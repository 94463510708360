import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ITrailerControlApiService } from './trailer-control-api.interface';
import { map, Observable, take } from 'rxjs';
import {
  TrailerControl,
  TrailerControlLabeled,
} from '../domain/TrailerControl.model';
import { TrailerControlCreate } from '../domain/TrailerControlCreate.model';
import { ResponseApi } from '../../../shared/infrastructure/models/response-api.interface';
import {
  TrailerControlApiModel,
  TrailerControlLabeledApiModel,
} from './models/trailer-control-api.model';
import { TrailerControlReport } from '../../trailer-control-reports/domain/TrailerControlReport.model';
import { TrailerControlHistory } from '../domain/TrailerControlHistory.model';

@Injectable({
  providedIn: 'root',
})
export class TrailerControlApiService implements ITrailerControlApiService {
  private readonly BASE_URL = environment.baseUrl;
  private _http = inject(HttpClient);

  private _mapTrailerControlApiToTrailerControl(
    trailerControl: TrailerControlApiModel
  ): TrailerControl {
    return {
      id: trailerControl.ID,
      createdAt: trailerControl.CreatedAt,
      updatedAt: trailerControl.UpdatedAt,
      createdBy: trailerControl.createdBy,
      updatedBy: trailerControl.updatedBy,
      trailerOwnerId: trailerControl.trailerOwnerID,
      trailerId: trailerControl.trailerID,
      trailerName: trailerControl.trailerName,
      companyBranchId: trailerControl.companyBranchId,
      isEmpty: trailerControl.isEmpty,
      isLoaded: trailerControl.isLoaded,
      sealNumber: trailerControl.sealNumber,
      typeSeal: trailerControl.typeSeal,
      ubication: trailerControl.ubication,
      isUnfit: trailerControl.isUnfit,
      details: trailerControl.details,
      latitude: trailerControl.latitude,
      longitude: trailerControl.longitude,
    };
  }

  private _mapTrailerControlsApiToTrailerControls(
    trailerControls: TrailerControlApiModel[]
  ): TrailerControl[] {
    return trailerControls.map((trailerControl) =>
      this._mapTrailerControlApiToTrailerControl(trailerControl)
    );
  }

  private _mapTrailerControlLabeledApiToTrailerControlLabeled(
    trailerControl: TrailerControlLabeledApiModel
  ): TrailerControlLabeled {
    return {
      id: trailerControl.id,
      createdAt: trailerControl.createdAt,
      createdBy: trailerControl.createdBy,
      trailerId: trailerControl.trailerID,
      trailerName: trailerControl.trailerName,
      yard: trailerControl.yard,
      ubication: trailerControl.ubication,
    };
  }

  private _mapTrailerControlsLabeledApiToTrailerControlsLabeled(
    trailerControls: TrailerControlLabeledApiModel[]
  ): TrailerControlLabeled[] {
    return trailerControls.map((trailerControl) =>
      this._mapTrailerControlLabeledApiToTrailerControlLabeled(trailerControl)
    );
  }

  getMostRecentTrailerControl(trailerName: string): Observable<TrailerControl> {
    const url = `${this.BASE_URL}trailer-control/${trailerName}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) =>
        this._mapTrailerControlApiToTrailerControl(
          response.results as TrailerControlApiModel
        )
      )
    );
  }

  getLabeledsTrailers(): Observable<TrailerControlLabeled[]> {
    const url = `${this.BASE_URL}trailer-control-labeleds`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) =>
        this._mapTrailerControlsLabeledApiToTrailerControlsLabeled(
          response.results as TrailerControlLabeledApiModel[]
        )
      )
    );
  }

  getTrailerControlByName(
    trailerName: string
  ): Observable<TrailerControlReport> {
    const url = `${this.BASE_URL}trailer-control/report/trailerNumber/${trailerName}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => response.results as TrailerControlReport)
    );
  }

  getTrailerControlHistory(
    trailerId: number
  ): Observable<TrailerControlHistory> {
    const url = `${this.BASE_URL}trailer-control/report/history/${trailerId}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => response.results as TrailerControlHistory)
    );
  }

  createRecord(
    trailerControl: TrailerControlCreate
  ): Observable<TrailerControl> {
    const url = `${this.BASE_URL}trailer-control`;
    const headers = new HttpHeaders();
    return this._http.post<ResponseApi>(url, trailerControl, { headers }).pipe(
      take(1),
      map((response) =>
        this._mapTrailerControlApiToTrailerControl(
          response.results as TrailerControlApiModel
        )
      )
    );
  }
}

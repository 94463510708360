import { InjectionToken } from '@angular/core';
import { TrailerControlApiService } from '../trailer-control-api.service';

export const HTTP_TRAILER_CONTROL_SERVICE = new InjectionToken(
  'TrailerControlApiService'
);

export const TRAILER_CONTROL_PROVIDER = {
  useClass: TrailerControlApiService,
  provide: HTTP_TRAILER_CONTROL_SERVICE,
};
